// remove requirement for animate__animated
*[class*="animate__"] {
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    #app[data-server-rendered="true"]:not(.hydrated) & {
        animation: none!important;
        --animate-duration: 0;
    }

    #app[data-server-rendered="true"].hydrated & {
        --animate-duration: 0;
    }

    #app[data-server-rendered="true"].hydrated.activated & {
        --animate-duration: 1s;
    }

    &.animate--speed1000 {
        animation-duration: 1000ms;
    }

    &.animate--speed500 {
        animation-duration: 500ms;
    }
    &.animate--speed400 {
        animation-duration: 400ms;
    }

    &.animate--speed300 {
        animation-duration: 300ms;
    }

    &.animate--speed200 {
        animation-duration: 200ms;
    }

    &.animate--speed100 {
        animation-duration: 100ms;
    }
    &.animate--fastest {
        animation-duration: calc(var(--animate-duration) / 4);
    }

    &.animate--faster {
        animation-duration: calc(var(--animate-duration) / 2);
    }

    &.animate--fast {
        animation-duration: calc(var(--animate-duration) * 0.8);
    }

    &.animate--slow {
        animation-duration: calc(var(--animate-duration) * 2);
    }

    &.animate--slower {
        animation-duration: calc(var(--animate-duration) * 3);
    }

    &.animate--repeat-2 {
        animation-iteration-count: 2;
    }

    &.animate--repeat-3 {
        animation-iteration-count: 3;
    }
}

$animateCssAnimationDelays: 50,100,150,200,250,300,350,400,450,500,550,600,650,700,750,800,850,900,950,
1000,1050,1100,1150,1200,1250,1300,1350,1400,1450,1500,1550,1600,1650,1700,1750,1800,1850,1900,1950,2000,2250,2500,2750,3000;

@each $duration in $animateCssAnimationDelays {
    .animate--delay-#{$duration} {
        animation-delay: #{$duration}ms;
    }
}


// animation extensions
@keyframes fadeInUpSmall {
    from {
        opacity: 0;
        transform: translate3d(0, 5%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInUpSmall {
    animation-name: fadeInUpSmall;
}

@keyframes fadeInDownSmall {
    from {
        opacity: 0;
        transform: translate3d(0, -5%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.animate__fadeInDownSmall {
    animation-name: fadeInDownSmall;
}

// small hinge
@keyframes hingeSmall {
    0% {
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        transform: rotate3d(0, 0, 1, 5deg);
        animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        transform: rotate3d(0, 0, 1, 4deg);
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        transform: translate3d(0, 120px, 0);
        opacity: 0;
    }
}

@keyframes hingeSmallRtl {
    0% {
        animation-timing-function: ease-in-out;
    }

    20%,
    60% {
        transform: rotate3d(0, 0, 1, -5deg);
        animation-timing-function: ease-in-out;
    }

    40%,
    80% {
        transform: rotate3d(0, 0, 1, -4deg);
        animation-timing-function: ease-in-out;
        opacity: 1;
    }

    to {
        transform: translate3d(0, 120px, 0);
        opacity: 0;
    }
}


.animate__hingeSmall {
    animation-duration: calc(var(--animate-duration) * 2);
    animation-name: hingeSmall;
    transform-origin: top left;

    [dir=rtl] & {
        transform-origin: top right;
        animation-name: hingeSmallRtl;
    }
}

// animate variable override
:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}
